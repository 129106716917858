import { create } from "zustand";

const useAccountStore = create((set) => ({
	account: null, // Initial state should be an empty array or your default state
	accountValidating: true,
	accountStatus: null,
	setAccount: (newAccount) => set({ account: newAccount }),
	setAccountValidating: (newAccountValidating) => set({ accountValidating: newAccountValidating }),
	setAccountStatus: (newAccountStatus) => set({ accountStatus: newAccountStatus }),
}));

export default useAccountStore;
